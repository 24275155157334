<script lang="ts">
export default {
  name: 'app-card-news'
};
</script>

<script lang="ts" setup>
import GlobalsHelpers from '@/helpers/globalsHelpers';
const props = defineProps({
  img: {
    type: String
  },
  titulo: {
    type: String
  },
  slug: {
    type: String
  },
  fecha: {
    type: String
  },
  h2: {
    type: Boolean,
    default: true
  },
  alt: {
    type: String,
    default: ''
  },


});



</script>

<template lang="pug">
nuxt-link(:to="`/noticias/${props.slug}/`")
  article.app-card-news(:style="{ backgroundImage: `url(${props.img})` }" :title="props.alt" )
    header.app-card-news__header.h-100.d-flex.align-items-center
      .app-card-news__content
          time {{GlobalsHelpers.formatDateStandard(new Date(props.fecha))}}
          h3(v-if="h2") {{props.titulo}}
          p.h2(v-if="!h2") {{props.titulo}}
</template>

<style lang="scss">
@import 'index';
</style>